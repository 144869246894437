@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');
@import "bootstrap-grid.css";
@import "header";

:root {
    --colorPrimaryDark : #0c2e9d;
    --colorPrimary     : #2446b5;
    --colorAccent      : #ababab;
}

* {
    box-sizing : border-box
}

body {
    font-family : 'Playfair Display', serif;
    font-size   : 1rem;
}

.ui.error.message, .ui.success.message, .ui.warning.message {
    display    : block;
    padding    : 5px 5px;
    font-size  : 12px;
    text-align : center;
}

.clearfix::after {
    clear   : both;
    content : '';
    display : block;
}

span.badge {
    position       : absolute;
    top            : 0;

    background     : red;
    height         : 16px;
    width          : auto;
    padding-left   : 5px;
    padding-right  : 5px;
    line-height    : 16px;
    vertical-align : middle;
    display        : block;
    border-radius  : 2px;
    text-align     : center;
    color          : white;
    font-size      : 10px;
}

.h-100 {
    height : 100%;
}

div.pdf_container {
    height : calc(100vh - 40px);
}

/*form data*/
.ui.dropdown, .ui.selection.dropdown {
    height         : 30px;
    min-height     : 30px;
    line-height    : 30px;
    vertical-align : middle;
    padding        : 0 5px;
}

.ui.dropdown, .ui.selection.dropdown .text {
    font-size : 13px;
}

.ui.selection.dropdown div.text {
    text-overflow : ellipsis;
    overflow      : hidden;
    white-space   : nowrap;
    width         : calc(100% - 10px);
    height        : 100%;
}

.ui.selection.dropdown > .delete.icon, .ui.selection.dropdown > .dropdown.icon, .ui.selection.dropdown > .search.icon {
    height  : 30px;
    padding : 8px 5px;
    margin  : -10px -10px 0 0;
}

.ui.negative.button, .ui.negative.buttons .button {
    background : #560c0c;
}

.ui.positive.button, .ui.positive.buttons .button {
    background : #153e05;
}

.ui.primary.button, .ui.primary.buttons .button {
    background : var(--colorPrimaryDark);
}

.ui.grey.label, .ui.grey.labels .label {
    background    : var(--colorPrimaryDark) !important;;

    white-space   : nowrap;
    overflow      : hidden;
    text-overflow : ellipsis;
}

.custom-file-upload {
    border  : 1px solid #cccccc;
    display : inline-block;
    padding : 6px 12px;
    cursor  : pointer;
    width   : 100%;
}

.custom-file-upload.has_file {
    border : 1px solid #134e03;
}

.input_file {
    width    : 0.1px;
    height   : 0.1px;
    opacity  : 0;
    overflow : hidden;
    position : absolute;
    z-index  : -1;
}

img {
    max-height : 100%;
}

input.date_input {
    width          : 100%;
    height         : 29px;
    padding        : 0 10px;
    line-height    : 29px;
    vertical-align : middle;
    border-radius  : 5px;
    border         : solid 1px lightgray;

    &:focus {
        border  : solid 1px #c3c1c1;
        outline : none;
    }
}

.react-tel-input .form-control {
    height : 30px;
    width  : calc(100% - 0px);
}

div.floating_button {
    height         : 30px;
    width          : 30px;
    background     : var(--colorPrimaryDark);
    position       : absolute;
    bottom         : 5px;
    right          : 5px;
    border-radius  : 50%;
    cursor         : pointer;
    border         : solid 1px var(--colorPrimaryDark);
    line-height    : 30px;
    vertical-align : middle;
    text-align     : center;

    i {
        color     : white;
        font-size : 15px;
    }

    &:hover {
        background : white;

        i {
            color : var(--colorPrimaryDark);
        }
    }
}

/*form content*/
div.loan_form {
    padding : 5px;

    & > div {
        background    : whitesmoke;
        padding       : 10px;
        height        : 100%;
        border-radius : 5px;
    }
}

div.disburse_summary {
    text-align    : center;
    margin-bottom : 20px;

    div.title {
        font-weight   : bold;
        font-size     : 13px;
        color         : black;
        margin-bottom : 5px;
        margin-top    : 15px;
    }

    div.amount {
        color       : #034b03;
        font-size   : 30px;
        font-weight : bold;
    }
}

.form {
    border-radius : 0;
    padding       : 5px 5px;
    height        : 100%;
    background    : whitesmoke;

    &.ui.form {
        height : auto;
    }

    div.form_header {
        padding                 : 5px;
        background              : var(--colorPrimaryDark);
        border-top-left-radius  : 0;
        border-top-right-radius : 0;
        text-align              : center;
        font-weight             : bold;
        color                   : white;
        height                  : 30px;
        margin-bottom           : 5px;
    }

    div.form_content {
        overflow-y    : auto;
        height        : calc(100% - 80px);
        margin-bottom : 5px;
        overflow-x    : hidden;
        position      : relative;

        &.no_header {
            height : calc(100% - 45px);
        }

        div.collateral_images {
            width    : 100%;
            padding  : 5px;
            position : relative;

            img {
                border : solid 2px white;
                width  : 100%;
                height : auto;
            }

            div.delete_image {
                position      : absolute;
                top           : 20px;
                left          : 20px;
                background    : red;
                height        : 20px;
                width         : 20px;
                cursor        : pointer;
                border-radius : 5px;
                text-align    : center;
                color         : white;
                font-size     : 12px;
            }
        }

        div.add_collateral {
            position      : absolute;
            bottom        : 55px;
            right         : 20px;
            height        : 30px;
            width         : 30px;
            border-radius : 50%;
            background    : var(--colorPrimaryDark);

            label {
                vertical-align : middle;
                line-height    : 30px;
                color          : white;
                display        : block;
                width          : 100%;
                height         : 100%;
                cursor         : pointer;
                text-align     : center;
            }
        }
    }

    div.form_footer {
        padding                    : 5px;
        background                 : white;
        border-bottom-left-radius  : 0;
        border-bottom-right-radius : 0;
        height                     : 38px;
    }
}

form.form {
    padding : 0;
}

.ui.form textarea, textarea {
    resize    : none;
    font-size : 13px;
}

input, optgroup, select, textarea {
    font-size : 13px;
}

.ui.input > input {
    padding        : 0 10px;
    line-height    : 28px;
    vertical-align : middle;
}

/*general table data*/
div.table_container {
    overflow-y : auto;
    overflow-x : auto;
}

.ui.table {
    margin : 0;

    thead {
        position   : sticky !important;
        top        : 0;
        z-index    : 2;
        background : var(--colorPrimaryDark);
    }

    tbody tr {
        &:hover {
            cursor : pointer;
        }

        &.reversed {
            color          : red;
            pointer-events : none;
        }
    }

    thead tr th, tbody tr td {
        vertical-align : middle;
    }

    &.has_total tbody tr:last-child, tbody tr.has_total {
        font-weight : 800;
        background  : rgba(85, 83, 83, 0.5) !important;
    }

    &.inverted {
        &.grey {
            background-color : #e2e2e2 !important;
            color            : #000000 !important;
        }

        &.selectable tbody tr:hover {
            //background : var(--colorPrimary) !important;
            background : rgba(85, 83, 83, 0.5) !important;
            color      : white !important;
        }

        &.striped tbody tr:nth-child(2n), &.striped > tr:nth-child(2n) {
            background-color : #d4d4d4
        }

        tr td {
            border-color : #c2bbbb !important;
        }
    }

    td, th {
        &.amount_row {
            width : 120px;
        }

        &.time_row {
            width : 140px;
        }
    }

    &.compact {
        td {
            padding : 10px 6px;

            &.spanned_color {
                background : #134e03;
                padding    : 0;
                position   : relative;

                div.days_spanned {
                    background : #560c0c;
                    height     : 37.56px;
                }

                div.date {
                    background     : transparent;
                    height         : 37.56px;
                    line-height    : 37.56px;
                    vertical-align : middle;
                    padding        : 0 5px;
                    color          : white;
                    position       : absolute;
                    left           : 0;
                    top            : 0;
                }
            }
        }
    }

    &:not(.structured) {
        thead, tbody tr {
            display      : table;
            width        : 100%;
            table-layout : fixed;
        }
    }
}

/*login data*/
div.login_container {
    position         : absolute;
    top              : 0;
    z-index          : 1000;
    height           : 100vh;
    background-color : rgb(208, 212, 220);
    padding          : calc((100vh - 175px) / 3) calc((100% - 350px) / 2);

    div.login_modal {
        padding    : 15px 20px;
        width      : 350px;
        height     : auto;
        background : white;

        h2 {
            display     : block;
            text-align  : center;
            font-size   : 25px;
            padding     : 20px 0 10px 0;
            font-family : 'Times New Roman', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        }

        div.not_user {
            text-align    : center;
            margin-bottom : 5px;
            margin-top    : 5px;
            color         : black;
            font-size     : 90%;

            span.link {
                color           : var(--colorPrimaryDark);
                font-style      : italic;
                font-weight     : 600;
                cursor          : pointer;
                text-decoration : underline;
            }
        }
    }
}

/*loan creation*/
.loan_info_container {
    height : calc(100% - 40px);

    .ui.segment.create_loan_pane {
        height        : calc(100% - 55px);
        overflow-y    : auto;
        padding       : 5px;
        border-radius : 0;

        div.client_payment_summary {
            height        : 75px;
            border        : solid 1px lightgray;
            background    : #ffffff;
            border-radius : 0;
            padding       : 5px;
            margin-bottom : 0;
        }

        div.loan_payments_parent {
            height : calc(100% - 80px);
        }

        .loan_other_data {
            height : 100%;

            .ui.segment.other_loan_pane {
                height        : calc(100% - 55px);
                padding       : 0;
                border-radius : 0;
            }
        }
    }
}

div.modify_loan {
    padding    : 10px;
    background : white;

    &.extensions {
        height : 525px;
    }

    &.charges {
        height : 380px;
    }

    &.payments {
        height : 500px;
    }

    &.reschedules {
        height : 250px;
    }
}


div.modal_div {
    height        : auto;
    max-height    : calc(100vh - 100px);
    background    : white;
    border-radius : 0;
    padding       : 5px;
    overflow-y    : auto;

    div.form_header {
        padding                 : 5px;
        background              : var(--colorPrimaryDark);
        border-top-left-radius  : 0;
        border-top-right-radius : 0;
        text-align              : center;
        font-weight             : bold;
        color                   : white;
        height                  : 30px;
        margin-bottom           : 5px;
    }

    &.modal_form {
        div.table_container {
            overflow-y : auto;
            overflow-x : auto;
            height     : auto;
            max-height : calc(100vh - 110px);
            background : whitesmoke;
            padding    : 0;

            & > table {
                min-width : 100%;
            }
        }

        div.form_content {
            overflow-y    : auto;
            height        : calc(100% - 75px);
            margin-bottom : 5px;
            overflow-x    : hidden;
            position      : relative;

            &.rights_form {
                & > div.field {
                    padding    : 5px 10px;
                    background : whitesmoke;

                    &:nth-child(2n-1) {
                        background : white;
                    }
                }
            }
        }

        div.form_footer {
            padding    : 5px;
            background : white;
            height     : 38px;
        }
    }

    &.payment_modal {
        .payment_amount.target_unreached > input {
            background : #ff6600;
            color      : white;
        }

        .payment_amount.invalid > input {
            color : red;
        }

        .payment_amount.loan_complete > input {
            background : green;
            color      : white;
        }

        .payment_amount.valid > input {
            color : green;
        }
    }

    &.loan_types {
        height : 400px;
    }

    &.creditor_modal, &.salary_scales {
        height : calc(100vh - 150px);
    }

    &.creditor_loan {
        height : calc(100vh - 50px);
    }

    &.creditor_payments {
        height : calc(100vh - 100px);
    }

    &.creditor_loan {
        flex-direction : column;
        display        : flex;

        & > div.table_container {
            flex : 1;
        }

        & > div.creditor_loan_form {
            background : whitesmoke;
            padding    : 5px;
            margin     : 5px 0;
        }
    }

    &.access_rights {
        height : calc(100vh - 100px);

        div.accordion {
            height     : calc(100% - 35px);
            overflow-y : auto;
        }
    }
}

.ui.table {
    tbody {
        td {
            white-space   : nowrap;
            overflow      : hidden;
            text-overflow : ellipsis;
        }
    }

    &.calendar_table {
        border-radius : 0;
        border        : solid 1px var(--colorPrimaryDark);

        thead {
            th {
                color      : var(--colorPrimaryDark);
                background : white;
                text-align : center;
                border     : none;
            }
        }

        tbody {
            background : white;

            div {
                div.month {
                    color       : white;
                    padding     : 3px;
                    font-size   : 10px;
                    line-height : 12px;
                }

                div.day {
                    color       : white;
                    padding     : 3px;
                    font-weight : 700;
                    font-size   : 150%;
                    margin-top  : 10px;
                }

                div.due_loan, div.pending, div.payments {
                    position       : absolute;
                    color          : white;
                    width          : auto;
                    height         : 15px;
                    line-height    : 15px;
                    vertical-align : middle;
                    font-size      : 10px;
                    cursor         : pointer;
                    border-radius  : 3px;
                    padding        : 0 5px 0px 5px;

                    &:hover {
                        background  : transparent;
                        font-size   : 15px;
                        font-weight : 600;
                        color       : #7e1818;
                    }
                }

                div.due_loan {
                    background : #7e1818;
                    top        : 3px;
                    left       : 3px;

                    &:hover {
                        color : #7e1818;
                    }
                }

                div.pending {
                    background : #d7391d;
                    bottom     : 3px;
                    right      : 3px;

                    &:hover {
                        color : #d7391d;
                    }
                }

                div.payments {
                    background : #246d0a;
                    bottom     : 3px;
                    left       : 3px;

                    &:hover {
                        color : #246d0a;
                    }
                }
            }

            div.even {
                background : var(--colorPrimaryDark);
            }

            div.odd {
                background : var(--colorPrimary);
            }

            div.even, div.odd {
                border : solid 1px transparent;

                &:hover {
                    border : solid 1px white;
                }
            }
        }
    }
}


