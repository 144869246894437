div.page_wrapper {
    height : 100vh;

    &.toggled {
        nav.sidebar_wrapper {
            left : 0;

            div.show_sidebar {
                left       : -60px;
                background : red;
            }
        }

        main.page_content {
            width       : calc(100% - 260px);
            margin-left : 260px;
        }
    }

    div.show_sidebar {
        position         : fixed;
        left             : 0;
        top              : 10px;
        border-radius    : 0 4px 4px 0;
        width            : 35px;
        transition-delay : 0.3s;
        background       : green;
        display          : none;
    }

    nav.sidebar_wrapper {
        background : var(--colorPrimary);
        width      : 260px;
        height     : 100%;
        max-height : 100%;
        position   : fixed;
        top        : 0;
        left       : -300px;
        z-index    : 999;

        div.sidebar_content {
            max-height : calc(100% - 30px);
            height     : calc(100% - 30px);
            overflow-y : auto;
            position   : relative;

            div.sidebar_brand {
                padding     : 10px 20px;
                display     : flex;
                align-items : center;

                div.brand_name {
                    text-transform : uppercase;
                    font-weight    : bold;
                    flex-grow      : 1;
                    color          : #ffffff;
                }

                div.close_sidebar {
                    cursor    : pointer;
                    font-size : 20px;
                    display   : none;
                }
            }

            div.sidebar_header {
                padding    : 20px;
                overflow   : hidden;
                border-top : 1px solid #ffffff;

                div.user_pic {
                    float         : left;
                    width         : 60px;
                    padding       : 2px;
                    border-radius : 12px;
                    margin-right  : 15px;
                    overflow      : hidden;

                    img {
                        object-fit : cover;
                        height     : 100%;
                        width      : 100%;
                    }
                }

                div.user_info {
                    float : left;
                    color : #ffffff;

                    & > span {
                        display : block;
                        color   : #ffffff;
                    }

                    span.branch_name {
                        font-size   : 12px;
                        font-weight : bold;
                    }

                    span.user_name {
                        font-size : 11px;
                    }

                    span.user_status {
                        font-size  : 11px;
                        margin-top : 4px;

                        i {
                            font-size    : 8px;
                            margin-right : 4px;
                            color        : #5cb85c;
                        }

                        span.user_role {
                            font-size : 12px;
                            color     : #ffffff;
                        }
                    }
                }
            }

            div.sidebar_menu {
                padding-bottom : 10px;
                border-top     : 1px solid #ffffff;

                span.badge {
                    right : 20px;
                }

                span.menu_item {
                    color           : white;
                    cursor          : pointer;
                    display         : inline-block;
                    width           : 100%;
                    text-decoration : none;
                    position        : relative;
                    padding         : 8px 30px 8px 20px;

                    white-space     : nowrap;
                    overflow        : hidden;
                    text-overflow   : ellipsis;

                    &:hover, &.active {
                        color       : #16c7ff;
                        text-shadow : 0 0 10px rgba(22, 199, 255, 0.5);

                        & > i:before {
                            display   : inline-block;
                            animation : swing ease-in-out 0.5s 1 alternate;
                        }
                    }

                    i {
                        margin-right  : 10px;
                        font-size     : 12px;
                        width         : 30px;
                        height        : 30px;
                        line-height   : 30px;
                        text-align    : center;
                        border-radius : 4px;
                        background    : var(--colorPrimaryDark);
                    }
                }

                ul {
                    list-style-type : none;
                    padding         : 0;
                    margin          : 0;

                    li.sidebar_dropdown {
                        &.active > span.menu_item:after {
                            transform : rotate(90deg);
                            right     : 17px;
                        }

                        & > span.menu_item:after {
                            font-family             : "Material Icons", serif;
                            font-weight             : 400;
                            content                 : "\e5cc";
                            font-style              : normal;
                            display                 : inline-block;
                            font-variant            : normal;
                            text-rendering          : auto;
                            -webkit-font-smoothing  : antialiased;
                            -moz-osx-font-smoothing : grayscale;
                            text-align              : center;
                            background              : 0 0;
                            position                : absolute;
                            right                   : 15px;
                            top                     : 14px;
                        }

                        div.sidebar_submenu {
                            display    : none;
                            background : var(--colorPrimaryDark);

                            &.show {
                                display : block;
                            }

                            ul {
                                padding : 5px 0;

                                li {
                                    padding-left : 25px;
                                    font-size    : 13px;

                                    span.menu_item {
                                        &:before {
                                            content                 : "\e836";
                                            font-family             : "Material Icons", serif;
                                            font-weight             : 400;
                                            font-style              : normal;
                                            display                 : inline-block;
                                            text-align              : center;
                                            text-decoration         : none;
                                            -webkit-font-smoothing  : antialiased;
                                            -moz-osx-font-smoothing : grayscale;
                                            margin-right            : 10px;
                                            font-size               : 8px;
                                        }

                                        span.label, span.badge {
                                            float       : right;
                                            margin-top  : 8px;
                                            margin-left : 5px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        div.sidebar_content.desktop {
            overflow-y : hidden;
        }

        div.sidebar_footer {
            position   : absolute;
            width      : 100%;
            bottom     : 0;
            display    : flex;
            background : #3a3f48;
            box-shadow : 0px -1px 5px #282c33;
            border-top : 1px solid #464a52;
            padding    : 8px 0;

            span.footer_item {
                text-align  : center;
                height      : 25px;
                line-height : 25px;
                position    : relative;
                color       : #818896;
                cursor      : pointer;

                &:first-child {
                    width       : 30px;
                    border-left : none;
                }

                &:not(:first-child) {
                    flex : 1;
                }

                &:last-child {
                    border-right : none;
                }

                &:hover {
                    color : #b8bfce;
                }

                i {
                    color : white;
                }

                span.notification {
                    right : -5px;
                }
            }
        }
    }

    main.page_content {
        display     : inline-block;
        padding     : 0;
        overflow-x  : hidden;
        height      : 100%;
        width       : 100%;
        margin-left : 0;

        & > div {
            padding : 5px;
            height  : 100%;

            div.dashboard_item {
                padding       : 10px;
                background    : var(--colorPrimaryDark);
                border-radius : 4px;
                color         : white;

                & > div {
                    display        : inline-block;
                    vertical-align : middle;

                    &:nth-child(1) {
                        width        : 40px;
                        margin-right : 10px;

                        & > i {
                            font-size      : 25px;
                            border         : solid 1px white;
                            height         : 40px;
                            width          : 40px;
                            border-radius  : 50%;
                            text-align     : center;
                            vertical-align : middle;
                            line-height    : 40px;
                        }
                    }

                    &:nth-child(2) {
                        width  : calc(100% - 50px);
                        height : 40px;

                        div.title {
                            font-size : 80%;
                        }

                        div.money {
                            font-weight : 600;
                            font-size   : 100%;
                        }
                    }
                }
            }

            div.content_bar {
                height         : 40px;
                border         : solid 1px lightgray;
                background     : #ffffff;
                border-radius  : 0;
                padding        : 5px;
                margin-bottom  : 5px;
                position       : relative;
                flex-direction : row;
                display        : flex;

                div.search_bar {
                    position       : relative;
                    flex           : 1;
                    vertical-align : top;
                    height         : 30px;


                    .menu.visible {
                        position : absolute;
                    }

                    & > div {
                        position       : relative;
                        display        : inline-block;
                        width          : 160px;
                        margin-right   : 5px;
                        vertical-align : top;
                    }
                }

                div.button_bar {

                }
            }

            div.table_container {
                height     : calc(100% - 91px);
                overflow-y : auto;
                overflow-x : auto;
                background : whitesmoke;
                padding    : 0;

                &.full_height {
                    height : 100%;
                }

                &.container_no_footer {
                    height : calc(100% - 46px);
                }

                & > table {
                    min-width : 100%;
                }
            }

            div.table_footer {
                margin-top : 5px;
                text-align : center;

                &.payments_footer {
                    text-align : center;

                    & > span {
                        display       : inline-block;
                        margin-right  : 10px;
                        border-radius : 5px;
                        padding       : 2px 10px;

                        &.header {
                            background : var(--colorPrimaryDark);
                            color      : white;
                        }

                        &.text {
                            font-weight : 600;
                            background  : whitesmoke;
                            min-width   : 150px;
                            text-align  : center;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width : 768px) {
    div.page_wrapper.toggled nav.page_content {
        padding-left : 300px;
    }
}

.sidebar_wrapper,
.sidebar_wrapper .sidebar_dropdown > span.menu_item:after,
.sidebar_wrapper .sidebar_menu .sidebar_dropdown .sidebar_submenu li span.menu_item:before,
.sidebar_wrapper ul li span.menu_item i,
.page_wrapper .page_content,
.sidebar_wrapper .sidebar_menu ul li span.menu_item,
div.show_sidebar, div.close_sidebar {
    -webkit-transition : all 0.3s ease;
    -moz-transition    : all 0.3s ease;
    -ms-transition     : all 0.3s ease;
    -o-transition      : all 0.3s ease;
    transition         : all 0.3s ease;
}

/*calendar*/
.ui.table.calendar_table {
    tbody {
        td {
            border  : none;
            padding : 0;
        }

        div {
            text-align : center;
            cursor     : pointer;
            color      : black;
        }

        div.date {
            position : relative;
            padding  : 3px;
        }
    }
}

/*my dropdown*/
/* Dropdown Button */
div.multiselect {
    position : relative;
    display  : inline-block;
    width    : 100%;
    height   : 30px;

    div.trigger {
        width          : 100%;
        height         : 30px;
        border         : solid 1px lightgray;
        line-height    : 30px;
        vertical-align : middle;
        border-radius  : 5px;
        padding        : 0 10px;
        cursor         : pointer;
        position       : relative;

        & > div {
            display        : inline-block;
            vertical-align : top;
            height         : 100%;
            line-height    : 28px;
        }

        div.selection {
            width : 20px;

            div {
                background    : var(--colorPrimaryDark);
                height        : 17px;
                display       : inline-block;
                width         : auto;
                line-height   : 17px;
                color         : white;
                padding       : 0 3px;
                font-size     : 12px;
                font-weight   : 600;
                border-radius : 3px;
            }
        }

        div.placeholder {
            width       : calc(100% - 40px);
            font-size   : 14px;
            border      : none;
            line-height : 28px;

            input {
                height       : 20px;
                width        : 100%;
                outline      : none;
                border       : none;
                border-image : none;
                box-sizing   : unset;
            }
        }

        div.caret {
            text-align : center;
            width      : 20px;

            &::after {
                font-family    : 'Material Icons', serif;
                font-weight    : 600;
                text-align     : center;
                vertical-align : middle;
                content        : '\e5cf';
                font-size      : 120%;
            }
        }
    }

    div.select_content {
        display       : none;
        width         : 100%;
        position      : absolute;
        left          : 0;
        top           : 0;
        outline       : 0;
        min-width     : max-content;
        background    : #ffffff;
        text-shadow   : none;
        text-align    : left;
        border        : 1px solid rgba(34, 36, 38, .15);
        transition    : opacity .1s ease;
        z-index       : 11;
        will-change   : transform, opacity;
        height        : auto;
        max-height    : calc(80vh);
        overflow-y    : auto;

        box-shadow    : 0 0 3px 0 rgba(0, 0, 0, .08);
        border-radius : .28571429rem .28571429rem 0 0;

        &.show {
            display : block;

            &.up {
                top    : auto;
                bottom : 100%;
            }
        }

        div.ui.checkbox {
            color            : black;
            padding          : 10px 16px;
            text-decoration  : none;
            display          : block;
            background-color : #f1f0f0;

            &:nth-child(2n -1) {
                background-color : #ffffff;
            }
        }

    }
}

@keyframes swing {
    0% {
        transform : rotate(0deg);
    }
    10% {
        transform : rotate(10deg);
    }
    30% {
        transform : rotate(0deg);
    }
    40% {
        transform : rotate(-10deg);
    }
    50% {
        transform : rotate(0deg);
    }
    60% {
        transform : rotate(5deg);
    }
    70% {
        transform : rotate(0deg);
    }
    80% {
        transform : rotate(-5deg);
    }
    100% {
        transform : rotate(0deg);
    }
}

@keyframes sonar {
    0% {
        transform : scale(0.9);
        opacity   : 1;
    }
    100% {
        transform : scale(2);
        opacity   : 0;
    }
}

::-webkit-scrollbar {
    width  : 5px;
    height : 7px;
}

::-webkit-scrollbar-button {
    width  : 0;
    height : 0;
}

::-webkit-scrollbar-thumb {
    background    : #525965;
    border        : 0 none #ffffff;
    border-radius : 0;
}

::-webkit-scrollbar-thumb:hover {
    background : #525965;
}

::-webkit-scrollbar-thumb:active {
    background : #525965;
}

::-webkit-scrollbar-track {
    background    : transparent;
    border        : 0 none #ffffff;
    border-radius : 50px;
}

::-webkit-scrollbar-track:hover {
    background : transparent;
}

::-webkit-scrollbar-track:active {
    background : transparent;
}

::-webkit-scrollbar-corner {
    background : transparent;
}
